// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-container {
  position: relative;
}

.background-video {
  width: 100%;
  height: 60vh;
}

.background-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 340px) {
  .background-video {
    height: 40vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Hero/Hero.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".hero-container {\n  position: relative;\n}\n\n.background-video {\n  width: 100%;\n  height: 60vh;\n}\n\n.background-video video {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n@media only screen and (max-width: 340px) {\n  .background-video {\n    height: 40vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
